@font-face {
  font-family: 'Courgette', cursive;
  src: URL("fonts/Courgette-Regular.ttf") format("truetype");
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
  padding: 16px;
  overflow-y: auto;
  display: none;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .login-modal {
    padding-top: 36px;
    align-items: flex-start;
  }
}

.login-modal_box {
  position: relative;
  max-width: 836px;
  width: 100%;
  background-color: #000000;
  border: 1px solid #ff0000;
  padding: 56px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

@media (max-width: 500px) {
  .login-modal_box {
    padding: 32px 16px;
    gap: 36px;
  }
}

.login-modal_box > button {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  display: inline-block;
  min-width: auto;
  max-width: unset;
  width: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  overflow: hidden;
}

.login-modal_box > button::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.login-modal_box > button img {
  display: inline-block;
}

.login-modal_box-top {
  max-width: 636px;
}

.login-modal_box-top h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 24px 0;
  color: #ff0000;
}

@media (max-width: 600px) {
  .login-modal_box-top h2 {
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}

.login-modal_box-top p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
}

@media (max-width: 600px) {
  .login-modal_box-top p {
    font-size: 16px;
  }
}

.login-modal_box-top p span {
  font-family: 'Courgette', cursive;
  font-weight: 400;
  color: #ff0000;
}

.login-modal_box-body {
  max-width: 636px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
}

@media (max-width: 600px) {
  .login-modal_box-body {
    flex-direction: column;
  }
}

.login-modal_box-body > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 0 1 50%;
}

.login-modal_box-body > div > span {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: #191616;
  border-radius: 8px;
}

.login-modal_box-body > div > span > span {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.3;
  color: #ffffff;
}

.login-modal_box-body > div > span img {
  width: auto;
  height: 40px;
}

@media (max-width: 500px) {
  .login-modal_box-body > div > span img {
    height: 32px;
  }
}

.login-modal_box-bottom {
  max-width: 636px;
  text-align: center;
}

.login-modal_box-bottom button {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 1.3;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #ffffff;
  background-color: #ff0000;
  margin: 0;
  padding: 16px 48px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-modal_box-bottom button::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .login-modal_box-bottom button {
    font-size: 18px;
    padding: 12px 16px;
  }
}
